import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Image } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { images } from "../../assets";
import { commonFormComponentType } from "./FormComponent";

export default function FeedBack({
  error,
  touched,
  required,
  label,
  name,
  value,
  disabled,
  onChange,
  ...rest
}: commonFormComponentType & {onChange : (e:boolean) => void }) {
    const thumbUpSource = value === true ? images.create8D.like : images.create8D.like_inactive;
    const thumbDownSource = value === false ? images.create8D.dislike : images.create8D.dislike_inactive;
  return (
    <FormControl isInvalid={error && touched ? true : false} isRequired={required}>
      <FormLabel id={`${name}-label`} htmlFor={name}>{label}</FormLabel>
      <Flex id={name} {...rest}>
        <Box cursor={'pointer'} onClick={() => {if(!disabled) onChange(true)}} data-testid="cust_feedback-thumbup">
          <Image src={thumbUpSource}  />
        </Box>
        <Box mx={3} cursor={'pointer'} onClick={() => {if(!disabled) onChange(false)}} data-testid="cust_feedback-thumbdown" >
          <Image src={thumbDownSource}/>
        </Box>
      </Flex>
      <FormErrorMessage as="div">{error}</FormErrorMessage>
    </FormControl>
  );
}

export function FeedBackFormControl({ name, ...rest }: commonFormComponentType) {
  const [field, { error, touched }, helper] = useField(name);
  return (
    <FeedBack
      {...rest}
      {...field}
      error={error}
      touched={touched}
      value={field.value}
      onChange={(val) => helper.setValue(val)}
    />
  );
}
