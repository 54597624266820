import en from "./en.json";
import  I18n  from "i18n-js";

I18n.translations = { en };

I18n.defaultLocale = "en";

export function localeStrings(
  name: string,
  params: {
    [key: string]: string | number;
  } = {}
): string {
  const result = I18n.t(name, params);

  if (result.includes("[missing")) {
    return name;
  }

  return result;
}
