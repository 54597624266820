import { Grid, Box, Flex, Text } from "@chakra-ui/react";
import { FieldArray, useField } from "formik";
import { Fragment } from "react";
import { Trash3, PlusCircleFill } from "react-bootstrap-icons";

import TitleWithLine from "../../Component/ui/TitleWithLine";
import { localeStrings } from "../../utils/i18n";
import { useAlertContext } from "../../providers/AlertProvider";
import { InputFormControl,PhoneNumberFormControl } from "../../Component/formComponent";

import AccordionTitleDescription from './AccordionTitleDescription/AccordionTitleDescription.json';
import { AccordionComponent } from "./AccordionTitleDescription/Accordion";
import { EIGHT_8D_SECTION } from "../../utils/const";

function D1Form({view, audit8DLogId, data}: {view : boolean; audit8DLogId?: number; data?: any}) {
  const [,, helper] = useField("multiRecordUpdate");
  const [, { error },] = useField("teamMembers");
  const { openAlert, setAlertProp, closeAlert } = useAlertContext();

  function deleteTeamMember({remove,index}:any){
    deleteAlert(remove,index);
  }

  function deleteAlert(remove:any,index:number){
    setAlertProp({
      type: "confirmation",
      alertMessage: localeStrings("ALERT.DELETE_CONFIRMATION_MSG"),
      alertLabel: localeStrings("ALERT.DELETE_CONFIRMATION"),
      secondaryButtonLabel: localeStrings("COMMON.YES_DELETE"),
      primaryButtonLabel: localeStrings("COMMON.NO_DONT"),
      onClickPrimary: () => {
        closeAlert();
      },
      onClickSecondary: () => {
        helper.setValue(true); 
          remove(index)
          closeAlert();
      },
    });
    openAlert();
    return;
  }
    return (
      <>
       <Flex justifyContent={"space-between"}>
        <Text pb={2} fontWeight="bold" fontSize={'md'}>{EIGHT_8D_SECTION.D1_PURPOSE}</Text>
        {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
       </Flex>
        <AccordionComponent Title={AccordionTitleDescription.D1.Title} Description={AccordionTitleDescription.D1.Description} HighlightedText={AccordionTitleDescription.D1.HighlightedText}/>
        <TitleWithLine>{localeStrings("CREATE_8D_LOG.CHAMPION")}</TitleWithLine>
        <Grid templateColumns={["repeat(1, 1fr)","repeat(1, 1fr)","repeat(3, 1fr)","repeat(3, 1fr)"]} gap={6}>
          <InputFormControl
            required={true}
            placeholder={localeStrings("PLACEHOLDERS.ENTER_NAME")}
            label={localeStrings("CREATE_8D_LOG.NAME")}
            data-testid="champion_name"
            name="championName"
            disabled={view}
            showLength={false}
            maxLength={100}
          />
          <InputFormControl
            required={true}
            placeholder={localeStrings("PLACEHOLDERS.ENTER_TITLE")}
            label={localeStrings("CREATE_8D_LOG.TITLE")}
            data-testid="champion_title"
            name="championTitle"
            disabled={view}
            showLength={false}
            maxLength={250}
          />
          <InputFormControl
            required={true}
            placeholder={localeStrings("PLACEHOLDERS.EMAIL_ID")}
            label= {localeStrings("CREATE_8D_LOG.EMAIL")}
            data-testid="champion_phone"
            disabled={view}
            showLength={false}
            maxLength={250}
            name="championPhone"
          />
        </Grid>
        <FieldArray name="teamMembers">
          {({ unshift, remove, form }) => {
            const { values } = form;
            return (
              <>
              <Flex cursor={'pointer'} alignItems={'center'} >
                <TitleWithLine>{localeStrings("CREATE_8D_LOG.TEAM_MEMBERS")}</TitleWithLine>
                {!view && <Flex onClick={() =>{ unshift({ teamMemberTitle : "", teamMemberName: "", phoneNumber: ""}); helper.setValue(true); }}>
                  <PlusCircleFill data-testid='team_member_add_button' style={{marginLeft:10}} color="#2196F3" fontSize={20}/>
                  <Text ml={2} color={'#2196F3'}>{localeStrings("COMMON.ADD")}</Text>
                </Flex>}
              </Flex>
              {typeof error === "string" && <Text pb={2} color={"brand.red"}>{error}</Text>}
              <Box>
                {values.teamMembers.map((member: any, index: number) => {
                  return (
                    <Fragment  key={index}>
                    <Grid position={'relative'} border={'1px solid var(--tk-colors-brand-grayLight)'} bg={'#F6F7F8'} templateColumns={["repeat(1, 1fr)","repeat(1, 1fr)","repeat(2, 1fr)","repeat(3, 1fr)"]} 
                      gap={6} 
                      mb={8}
                      borderRadius={'5px'}
                      p={3}
                      >
                      <InputFormControl
                        required={data?.[0]?.purpose?.value === 1 ? false : true}
                        placeholder={localeStrings("PLACEHOLDERS.ENTER_NAME")}
                        label= {localeStrings("CREATE_8D_LOG.NAME")}
                        data-testid="team_member_name"
                        name={`teamMembers[${index}].teamMemberName`}
                        disabled={view}
                        showLength={false}
                        maxLength={100}
                        onChange={() => helper.setValue(true)}
                      />
                      <InputFormControl
                        required={data?.[0]?.purpose?.value === 1 ? false : true}
                        placeholder={localeStrings("PLACEHOLDERS.ENTER_TITLE")}
                        label={localeStrings("CREATE_8D_LOG.TITLE")}
                        data-testid="team_member_title"
                        name={`teamMembers[${index}].teamMemberTitle`}
                        disabled={view}
                        showLength={false}
                        maxLength={250}
                        onChange={() => helper.setValue(true)}
                      />
                        <InputFormControl
                          required={data?.[0]?.purpose?.value === 1 ? false : true}
                          placeholder={localeStrings("PLACEHOLDERS.EMAIL_ID")}
                          label= {localeStrings("CREATE_8D_LOG.EMAIL")}
                          data-testid="team_member_phone_number"
                          name={`teamMembers[${index}].phoneNumber`}
                          disabled={view}
                          showLength={false}
                          maxLength={250}
                          onChange={() => helper.setValue(true)}
                        />
                      <Flex alignItems="center">
                        {!view && <Box 
                          position={'absolute'} 
                          right={'10px'} 
                          cursor={'pointer'}
                          bottom={'-12px'} 
                          bg={'brand.white'}>
                            {values.teamMembers.length === 1 && data?.[0]?.purpose?.value === 1 && index === 0  && 
                              <Box
                                color="#ff4e4e"
                                borderRadius={'50%'}
                                border={'1px solid #ff4e4e'}
                                p={1}
                                data-testid="team_member_delete_button_index_first" 
                                onClick={() => {deleteTeamMember({remove, index}) }}
                              >
                              <Trash3 />
                            </Box>}
                          {values.teamMembers.length > 1 &&
                            <Box
                              color="#ff4e4e"
                              borderRadius={'50%'}
                              border={'1px solid #ff4e4e'}
                              p={1}
                              data-testid="team_member_delete_button"
                              onClick={() => {deleteTeamMember({remove,index})}}>
                              <Trash3 />
                            </Box>
                          }
                        </Box>}
                      </Flex>
                    </Grid>
                    </Fragment>
                  );
                })}
              </Box>
              </>
            );
          }}
        </FieldArray>
      </>
    );
}

export default D1Form;
