import moment from "moment";
import { actionTrackerType } from "../Component/DynamicFormikForm/components/YesOrNO/YesOrNo";
import { elementFlavorType, elementValidationType, pendingActionItemType } from "../Component/DynamicFormikForm/types";
import { ACTION_TRACKER_STATUS_ID, AUDIT_STATUS_ID, DEFAULT_DATE_FORMAT, EIGHT_D_LISTING_HEADERS, EIGHT_D_MENU_OPTIONS, LOG_STATUS } from "./const";
import { localeStrings } from "./i18n";

export const isObjectEmpty = (target: object = {}): boolean => Object.keys(target).length === 0;


export const getCreatedDateAndTime = (date:string, removeTime?: boolean) => {
    const sDate = applyDateFormate({ date : date, format : "MM/DD/YYYY"});
    const sTime = applyDateFormate({date : date, format : "hh:mm A"});
    
    return date ? removeTime ? `${sDate}` : `${sDate} | ${sTime}` : "-";
};

type appDateFormatPayloadType = {
    date?: Date | string,
    format?: string,
    convertLocalTime?: boolean
};

export const applyDateFormate = ({
    date, format = DEFAULT_DATE_FORMAT,
    convertLocalTime = true
}: appDateFormatPayloadType) => {
    if (!moment(date).isValid()) {
        return "-";
    }

    return convertLocalTime ? moment.utc(date).local().format(format) : moment(date).format(format);
};



export const covertDateToUTC = (date : Date) => {
    return date ? moment.utc(date).format() : date
}

export type menuOpionType = {
    title: string,
    type: string
};

export function checkIsEmpty(obj:Object) {
    return obj ? Object.keys(obj).length === 0 : 0;
}

export const get8DMenuListByStatus = (statusId: number, isChild: boolean = false, allowEdit: boolean = true): menuOpionType[] => {

    const optionEdit: menuOpionType = {
        title: localeStrings('MENU_OPTIONS.EDIT'),
        type: EIGHT_D_MENU_OPTIONS.EDIT
    };

    const optionView: menuOpionType = {
        title: localeStrings('MENU_OPTIONS.VIEW'),
        type: EIGHT_D_MENU_OPTIONS.VIEW
    };

    const optionFil5Why: menuOpionType = {
        title: localeStrings('MENU_OPTIONS.FILL_5_WHY'),
        type: EIGHT_D_MENU_OPTIONS.FILL_5_WHY
    };

    const optionFill8D: menuOpionType = {
        title: localeStrings('MENU_OPTIONS.FILL_8D'),
        type: EIGHT_D_MENU_OPTIONS.FILL_8D
    };

    const optionSendCustLog: menuOpionType = {
        title: localeStrings('MENU_OPTIONS.SEND_8D_LOG'),
        type: EIGHT_D_MENU_OPTIONS.SEND_8D_LOG
    };

    const optionCreateChild: menuOpionType = {
        title: localeStrings('MENU_OPTIONS.CREATE_CHILD'),
        type: EIGHT_D_MENU_OPTIONS.CREATE_CHILD
    };

    const optionViewRevision: menuOpionType = {
        title: localeStrings('MENU_OPTIONS.VIEW_REVISION'),
        type: EIGHT_D_MENU_OPTIONS.VIEW_REVISION
    };

    
    const optionContainmentCustomerRejected: menuOpionType = {
        title: localeStrings('MENU_OPTIONS.CUSTOMER_REJECTED'),
        type: EIGHT_D_MENU_OPTIONS.CONTAINMENT_CUSTOMER_REJECTED
    };
    let options: menuOpionType[] = [];

    switch (statusId) {
    case LOG_STATUS.DRAFT:
        options = allowEdit ? [
            optionView,
            optionEdit
        ] : [optionView];
        break;

    case LOG_STATUS.CONTAINMENT_REVIEW:
        options = [optionView];
        break;

    case LOG_STATUS.CONTAINMENT_REVIEW_APPROVED:
        options = allowEdit ? [
            optionView,
            optionFil5Why,
            optionContainmentCustomerRejected
        ] : [optionView];
        break;

    case LOG_STATUS.CONTAINMENT_REVIEW_REJECTED:
        options = allowEdit ? [
            optionView,
            optionCreateChild
        ] : [optionView];
        break;

    case LOG_STATUS.SUBMITTED:
        options = [optionView];
        break;

    case LOG_STATUS.APPROVED:
        options = allowEdit ? [
            optionView,
            optionFill8D,
            optionSendCustLog,
        ] : [optionView];
        break;

    case LOG_STATUS.REJECTED:
        options = allowEdit ? [
            optionView,
            optionCreateChild
        ] : [optionView];
        break;

    case LOG_STATUS.CUSTOMER_REJECTED:
        options = allowEdit ? [
            optionView,
            optionCreateChild
        ] : [optionView];
        break;

    case LOG_STATUS.CUSTOMER_APPROVED:
        options = [optionView];
        break;
    default: break;
    case LOG_STATUS.CONTAINMENT_CUSTOMER_REJECTED:
        options = allowEdit ? [
            optionView,
            optionCreateChild
        ] : [optionView];
            break;
    }

    return isChild ? [...options, optionViewRevision] : options;
};

export const SHIFT_DROPDOWN_DATA = [
    { label: '01', value: '1' }, 
    { label: '02', value: '2' },
    { label: '03', value: '3' },
    { label: '04', value: '4' }
];

export const getStatusNameById = (id?: number) => {
    switch (id) {
    case AUDIT_STATUS_ID.DRAFT:
        return localeStrings('AUDITS.STATUS.DRAFT');

    case AUDIT_STATUS_ID.YET_TO_START:
        return localeStrings('AUDITS.STATUS.YET_TO_START');

    case AUDIT_STATUS_ID.COMPLETED:
        return localeStrings('AUDITS.STATUS.COMPLETED');

    case AUDIT_STATUS_ID.REJECTED:
        return localeStrings('AUDITS.STATUS.REJECTED');

    case AUDIT_STATUS_ID.STOPPED:
        return localeStrings('AUDITS.STATUS.STOPEED');

    case AUDIT_STATUS_ID.OVER_DUE:
        return localeStrings('AUDITS.STATUS.OVER_DUE');

    default:
        return "";
    }
};

export function addAfter(array : any[], index : number, newItem : any) {
    return [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index)
    ];
}


export const checkAllowSaveDraft = (response: any, emptyObjectFilterKey?: string): boolean => {
    Object.keys(response).forEach((key) => {
        if (response[key] === "" || response?.id) {
            response[key] = undefined;
        }
    });

    let userInput = JSON.parse(JSON.stringify(response));
    if (emptyObjectFilterKey) {
        const targetArray = userInput[emptyObjectFilterKey] ?? [];
        const filtedValue = targetArray.filter((item:any) => {
            const innerValue =  item;
            Object.keys(innerValue).forEach((key) => {
                if (innerValue[key] === "" || innerValue?.id || innerValue?.serialNo) {
                    innerValue[key] = undefined;
                }
            });
            return !isObjectEmpty(JSON.parse(JSON.stringify(innerValue)));
        });

        userInput[emptyObjectFilterKey] = filtedValue?.length > 0 ? filtedValue : undefined;
        userInput = JSON.parse(JSON.stringify(userInput));
    }

    const allowDraft = !isObjectEmpty(userInput);


    return allowDraft;
};


export function FormatCostOfQuality(string:string) {
    return`$ ${string ? string.toString().includes(".") ? string : `${string}.00` :  "0.00"}`
}


export const checkIsPendingActionExist = (actionTrackers: pendingActionItemType[] = []): boolean => {
    let pendingAction = false;
    actionTrackers?.forEach((actionItem) => {
        if (actionItem.statusId === ACTION_TRACKER_STATUS_ID.INPROGRSS) {
            pendingAction = true;
        }
    });

    return pendingAction;
};

type generateIDPayloadPropsType = {
    prefix: string,
    id: number,
    padSize?: number,
    padString?: string
};

export const generateID = ({ prefix, id, padSize = 1, padString = "0" }: generateIDPayloadPropsType): string => {
    return `${prefix}${`${id}`}`;
};

export function groupArrByCol(arr:any, field:any){
    return arr?.reduce((previous:any, current:any) => {
      (previous[current[field]] =
        previous[current[field]] || []).push(current);
      return previous;
    }, {});
  }

  export const getElementType = (type: number, ): elementFlavorType | undefined => {
    switch (type) {
    case 1:
        return "text";
    case 2:
        return "textarea";
    case 3:
        return "choice";
    case 4:
        return "checkboxgroup";
    case 5:
        return "select";
    case 6:
        return "multiselect";
    case 7:
        return "file";
    default :

    }
};

export const getElementValidationType = (type: number): elementValidationType | undefined => {
    switch (type) {
    case 1:
    case 2:
        return  "string";

    case 3:
    case 5:
        return  "object";
    case 4:
    case 6:
    case 7:
        return  "array";
    default :
    }
};


export const getFlexFor8DListingHeader = (header : EIGHT_D_LISTING_HEADERS) => {
    switch(header) {
        case EIGHT_D_LISTING_HEADERS.ID : 
        case EIGHT_D_LISTING_HEADERS.COST_OF_QUALITY : 
        return 0.075;
        
        case EIGHT_D_LISTING_HEADERS.ACTION : 
        return 0.05;

        case EIGHT_D_LISTING_HEADERS.CHAMPION :  
        case EIGHT_D_LISTING_HEADERS.CREATED_BY :  
        case EIGHT_D_LISTING_HEADERS.CREATED_ON : 
        case EIGHT_D_LISTING_HEADERS.CUSTOMER :   
        case EIGHT_D_LISTING_HEADERS.LOCATION :   
        return 0.10;

        case EIGHT_D_LISTING_HEADERS.STATUS : 
        case EIGHT_D_LISTING_HEADERS.DEFECT_CODE : 
        return 0.15;

        default :
        return 0.1;
    }
}


export const AUDIT_AT_STATUS_ID = {
    COMPLETED : 1,
    DRAFT : 3,
    DELETE : 4
};

export const checkIfAlreadyActionItemExist = ({
    key,
    template
}: {key: string, template: actionTrackerType[]}): actionTrackerType | undefined => {
    return template?.length ? template?.find((actionItem) => actionItem?.selectedKey === key) : undefined;
};

export const generateStartDateEndDate = ({durationDays, formatString}:{durationDays:number, formatString?:string}) =>{
    var today = new Date();
    var startDate = new Date();
    startDate.setDate(today.getDate() - durationDays);
    return {startDate: formatString ? moment(startDate).format(formatString) : startDate.toISOString() , endDate: formatString ? moment(today).format(formatString) : today.toISOString()}
}

export const monthConversions :any = {
    'Jan' : 'January',
    'Feb' : 'February',
    'Mar' : 'March',
    'Apr' : 'April',
    'May' : 'May',
    'Jun' : 'June',
    'Jul' : 'July',
    'Aug' : 'August',
    'Sep' : 'September',
    'Oct' : 'October',
    'Nov' : 'November',
    'Dec' : 'December'
}