import {
  createContext,
  ReactNode,
  useContext,
  useState,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";

type FilterContextType = {
  [key: string]: { [key: string]: any };
};

type FilterContextDataType = {
  filters: FilterContextType;
  setFilters: Dispatch<SetStateAction<FilterContextType>>;
};

const FilterContext = createContext<FilterContextDataType>({
  filters: {},
  setFilters: () => {
    return {};
  },
});

export enum FILTER_MODULES {
  DASHBOARD_8D = "DASHBOARD_8D",
  AUDIT_DASHBOARD = "AUDIT_DASHBOARD",
  LIST_8D = "LIST_8D"
}

export const useFilterContext = (key: FILTER_MODULES, value: { [key: string]: any }) => {
  const { filters, setFilters }: FilterContextDataType = useContext(FilterContext);
  const setFilter = (e: FilterContextType) => {
    let newFilters = { ...filters, [key]: e };
    setFilters(newFilters);
  };

  useEffect(() => {
    if(!filters?.[key]) {
        setFilters({ ...filters, [key]: value });
    }
    
  }, [value]);
  return { filter: filters?.[key] ?? value, setFilter };
};

export const FilterContextProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useState<FilterContextType>({});
  return (
    <FilterContext.Provider value={{ filters, setFilters }}>{children}</FilterContext.Provider>
  );
};
