import { azureFetcher } from "./fetcher";
import API_ENDPOINT from "./endpoints";
import { ALLOWED_NO_RECORD_PER_PAGE } from "../utils/const";
import { postAuditFormRequestPayloadType } from "../Pages/PerformAudit/types";
export type auditRequestPayloadType = {
    prescduleID: string;
};

export const getAuditFormApi = async (payload: auditRequestPayloadType) => {
    const url = `${API_ENDPOINT.PERFORM_AUDIT}?PrescduleID=${payload.prescduleID}`;
    const { data } = await azureFetcher.get(url);
    return data;
};

export const getAuditNewFormApi = async (payload: {
    auditTemplateId: number;
    locationId : number;
}) => {
    const url = `${API_ENDPOINT.PERFORM_AUDIT}?auditTemplateId=${payload.auditTemplateId}&locationId=${payload.locationId}`;
    const { data } = await azureFetcher.get(url);
    return data;
};

type editAuditRequestPayloadType = {
    prescduleID: number
};

export const  fetchAuditDetailsApi = async (payload: editAuditRequestPayloadType) => {

    const { data } = await azureFetcher.get(`${API_ENDPOINT.EDIT_AUDIT}?prescheduleID=${payload.prescduleID}`);
    
    return data
};



export const postAuditFormApi = async (payload: postAuditFormRequestPayloadType, endpoint  : string) => {
    const data = await azureFetcher.post(endpoint, payload);
    return data;
};




type scheduleAuditListRequestPayloadType = {
  userName?: string;
  businessUnitId: number;
  locationId: number;
  pageNumber?: number;
  recordsPerPage?: number;
  searchString?: any | null;
  sortOrder?: number;
  isLoadMore?: boolean;
  startDate?: string,
  endDate?: string,
  auditorEmailId?: string,
  SortType?: string,
  SortColumn?: string,
  StatusID?: number
};

type exportExcelRequestPayloadType = {
  userName?: string;
  businessUnitId: number;
  locationId: number;
  pageNumber?: number;
  recordsPerPage?: number;
  searchString?: any | null;
  sortOrder?: number;
  isLoadMore?: boolean;
  startDate?: string;
  endDate?: string;
  ListCategory?: number;
  fileName: string;
};
export const getUpcomingAuditApi = async (payload: scheduleAuditListRequestPayloadType) => {
  let url = API_ENDPOINT.UPCOMING_AUDIT;
  const pageQuery = `?PageNumber=${payload.pageNumber ?? 1}&pageSize=${
    payload.recordsPerPage ?? ALLOWED_NO_RECORD_PER_PAGE
  }`;
  const filterQuery = `${payload.userName ? `&UserName=${payload.userName}` : ""}${
    payload.businessUnitId ? `&businessUnitId=${payload.businessUnitId}` : ""
  }${payload.locationId ? `&LocationId=${payload.locationId}` : ""}${
    payload.searchString ? `&auditTemplateId=${payload.searchString}` : ""
  }${payload.SortColumn ? `&SortColumn=${payload.SortColumn}` : ""}${
    payload.auditorEmailId ? `&auditorEmailId=${payload.auditorEmailId}` : ""
  }${payload.SortType ? `&SortType=${payload.SortType}` : ""}${
    payload.sortOrder ? `&SortOrder=${payload.sortOrder}` : ""}`;
  const dateFilterQuery = (payload.startDate ? `&StartDate=${payload.startDate}` : "") + (payload.endDate ? `&EndDate=${payload.endDate}` : "");

  url = url + pageQuery + filterQuery + dateFilterQuery;

  const { data } = await azureFetcher.get(url);

  return data;
};

export const getCompletedAuditApi = async (payload: scheduleAuditListRequestPayloadType) => {
  let url = API_ENDPOINT.COMPLETED_AUDIT;
  const pageQuery = `?PageNumber=${payload.pageNumber ?? 1}&pageSize=${payload.recordsPerPage ?? ALLOWED_NO_RECORD_PER_PAGE}`;
  const filterQuery = `${payload.userName ? `&UserName=${payload.userName}` : ""}${
    payload.businessUnitId ? `&businessUnitId=${payload.businessUnitId}` : ""
  }${payload.locationId ? `&LocationId=${payload.locationId}` : ""}${
    payload.searchString ? `&auditTemplateId=${payload.searchString}` : ""
  }${payload.auditorEmailId ? `&auditorEmailId=${payload.auditorEmailId}` : ""}${
    payload.SortColumn ? `&SortColumn=${payload.SortColumn}` : ''
  }${payload.SortType ? `&SortType=${payload.SortType}` : ''}${
    payload.StatusID ? `&StatusID=${payload.StatusID}` : ""
  }`;
  const dateFilterQuery = (payload.startDate ? `&StartDate=${payload.startDate}` : "") + (payload.endDate ? `&EndDate=${payload.endDate}` : "");

  url = url + pageQuery + filterQuery + dateFilterQuery;

  const { data } = await azureFetcher.get(url);
  
  return data;
};


export const updateAuditFormApi = async (payload: postAuditFormRequestPayloadType & { id : number}) => {

    const data  = await azureFetcher.put(`${API_ENDPOINT.UPDATE_AUDIT}?Id=${payload.id}`, payload);

    return data;
};


export async function deleteAttachmentAudit({ fileId, fileScope }: any) {
  const url = `?FileId=${fileId}&FileScope=${fileScope}`;
  const data = await azureFetcher.delete(API_ENDPOINT.DELETE_AUDIT_ATTACHMENT + url);
  if (data.status === 200) return data.status;
  return [];
}

export async function deleteCompletedAudit({ auditId }: any) { // Super admin can only delete a completed audit
  const data = await azureFetcher.delete(API_ENDPOINT.DELETE_COMPLETED_AUDIT(auditId));
  if (data.status === 200) return data.status;
  return [];
}

export async function markAuditasNotApplicable({ auditId, comments }: {auditId: any, comments: string}) { // Super admin can only delete a completed audit
  const data = await azureFetcher.put(API_ENDPOINT.MARK_AS_NOT_APPLICABLE(auditId,comments));
  if (data.status === 200) return data.status;
  return [];
}

export async function getAuditListApi(payload:scheduleAuditListRequestPayloadType) {
    let url = API_ENDPOINT.AUDIT_LIST;
    const pageQuery = `?PageNumber=${payload.pageNumber ?? 1}&pageSize=${
      payload.recordsPerPage ?? ALLOWED_NO_RECORD_PER_PAGE
    }`;
    const filterQuery = `${payload.userName ? `&UserName=${payload.userName}` : ""}${
      payload.businessUnitId ? `&businessUnitId=${payload.businessUnitId}` : ""
    }${payload.locationId ? `&LocationId=${payload.locationId}` : ""}${
      payload.searchString ? `&auditTemplateId=${payload.searchString}` : ""
    }${payload.sortOrder ? `&SortOrder=${payload.sortOrder}` : ""}`;
    
    url = url + pageQuery + filterQuery;
  
    const { data } = await azureFetcher.get(url);
  
    return data;
}

export const exportExcelAuditLog = async (payload: exportExcelRequestPayloadType) => {
  let url = '';
  const pageQuery = `?PageNumber=${payload.pageNumber ?? 1}&pageSize=${
    payload.recordsPerPage ?? ALLOWED_NO_RECORD_PER_PAGE
  }`;
  const filterQuery = `${payload.userName ? `&UserName=${payload.userName}` : ""}${
    payload.businessUnitId ? `&businessUnitId=${payload.businessUnitId}` : ""
  }${payload.locationId ? `&LocationId=${payload.locationId}` : ""}${
    payload.searchString ? `&auditTemplateId=${payload.searchString}` : ""
  }`;
  const dateFilterQuery = (payload.startDate ? `&startDate=${payload.startDate}` : "") + (payload.endDate ? `&endDate=${payload.endDate}` : "");
  let ListCategory = (payload.ListCategory ? `&ListCategory=${payload.ListCategory}` : "");
  url = url + pageQuery + filterQuery + dateFilterQuery + ListCategory;

  try {
    const { data } = await azureFetcher.get(API_ENDPOINT.EXPORT_EXCEL_AUDIT_LOG(url), {
      responseType: "blob",
    });

  if (data) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = payload.fileName;
    link.click();
    return data.data; 
  }
  return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};