import { Box,Text, Flex } from "@chakra-ui/react";

import { ImageUploaderFormControl,InputFormControl,TextAreaFormControl } from "../../Component/formComponent";
import { localeStrings } from "../../utils/i18n";
import AccordionTitleDescription from './AccordionTitleDescription/AccordionTitleDescription.json';
import { AccordionComponent } from "./AccordionTitleDescription/Accordion";
import { useField } from "formik";
import { EIGHT_8D_SECTION } from "../../utils/const";

function D2Form({view, audit8dHeaderId, audit8DLogId}: {view : boolean; audit8dHeaderId?:number; audit8DLogId ? : number }) {
  const [,, multiRecordHelper] = useField("multiRecordUpdate");
  return (
    <>
      <Flex justifyContent={"space-between"}>
        <Text pb={2} fontWeight="bold" fontSize={'md'}>{EIGHT_8D_SECTION.D2_CONCERN}</Text>
        {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
      </Flex>
      <AccordionComponent Title={AccordionTitleDescription.D2.Title} Description={AccordionTitleDescription.D2.Description} />
      <Box width={[ '100%','100%','75%','100%']}>
        <Box width={[ '100%','100%','40%','40%']} mb={5}>
          <InputFormControl
            required={false}
            placeholder={localeStrings("PLACEHOLDERS.ENTER_JOB_NUMBER")}
            label = {localeStrings("CREATE_8D_LOG.JOB_NUMBER")}
            data-testid="jobNumber-test"
            name="jobNumber"
            infoIcon={true}
            showLength={false}
            maxLength={100}
            disabled={true}
          />
        </Box>
        <TextAreaFormControl
           required={true}
          placeholder={localeStrings("PLACEHOLDERS.PROBLEM_DESCRIPTION")}
          label = {localeStrings("CREATE_8D_LOG.PROBLEM_DESCRIPTION")}
          data-testid="description-test"
          name="description"
          maxLength={1000}
          disabled={view} 
        />
        <Box my="5">
          <ImageUploaderFormControl
            label = {localeStrings('CREATE_8D_LOG.UPLOAD_IMAGE')}
            data-testid="imageUploader-test"
            name="attachments"
            required={false}
            responsive={!(window.innerWidth >= 600)}
            disabled={view}
            audit8dHeaderId={audit8dHeaderId}
            onChange={() => multiRecordHelper.setValue(true)}
          />
        </Box>
      </Box>
    </>
  );
}

export default D2Form;
