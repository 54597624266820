import { Text, Heading } from "@chakra-ui/react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon} from '@chakra-ui/react'
  import {InfoOutlineIcon} from '@chakra-ui/icons';

type props = {
    Title :string;
    Description :string;
    Title2 ?:string;
    Description2 ?:string;
    HighlightedText ?:string;
}

export function AccordionComponent({Title, Description, Title2, Description2, HighlightedText}:props){
    return(
        <>
        <Accordion  defaultIndex={[0]} allowToggle mb={4} backgroundColor={'var(--tk-colors-brand-bg)'}  borderRadius={'5px'} _hover = {{ bg: 'var(--tk-colors-brand-bg)' }}>
          <AccordionItem borderRightWidth={'1px'} borderLeftWidth={'1px'} borderRadius={'5px'} borderColor={'#E9E9E9'} >
            <Heading as='h2' bg={'brand.bg'}  >
              <AccordionButton _hover = {{ bg: 'var(--tk-colors-brand-bg)' }}>
                  <>
                  <InfoOutlineIcon color={'var(--tk-colors-brand-grayMedium)'} w={3} h={3} mr={2}/>
                  <Text fontSize={'var(--tk-fontSizes-sm)'} mt={0.5} flex='1' textAlign='left'>
                    {Title}
                  </Text>
                  </>
                  <AccordionIcon color={'brand.grayMedium'} />
              </AccordionButton>
            </Heading>
            <AccordionPanel pb={4}>
              <Text fontSize={'var(--tk-fontSizes-sm)'}>{Description}</Text>
              {HighlightedText ? <Text mt={3} fontSize={'var(--tk-fontSizes-sm)'} color={'var(--tk-colors-brand-secondaryDark)'}>{HighlightedText}</Text> : <></>}
              {Title2 ? <> 
                <Text mt={2} fontSize={'var(--tk-fontSizes-sm)'}>{Title2}</Text>
                <Text mt={2}>{Description2}</Text> </> : <></>}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        </>
    )
}