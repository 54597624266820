import { ChakraProvider, ColorModeScript, extendTheme, type ThemeConfig } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

export let brand = {
  primaryBrand: "#00A0F5",
  secondaryDark: "#003C7D",
  secondaryMedium: "#0055BE",
  secondaryBright: "#0078DC",
  secondaryLight: "#50AFE1",
  secondaryNormal: "#73C3F0",
  orange: "#FFB400",
  purple: "#A51482",
  red: "#E60050",
  yellow: "#FAE100",
  green: "#9BC832",
  turquoise: "#0F968C",
  black: "#000000",
  grayDark: "#4B5564",
  grayMedium: "#78879B",
  grayLight: "#D9DEE8",
  gray: "#EEF0F2",
  white: "#FFFFFF",
  inactiveGray: "#C0C3C9",
  hoverLightBlue: "#D5EDFB",
  warning: "#D49704",
  alertWarning: "#FFEDC3",
  bg: "#F6F7F8"
}
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
  cssVarPrefix: "tk",
};
const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "sm",
    color : "black",
    marginBottom : "0"
  },
};
const Heading: ComponentStyleConfig = {
  sizes: {
    "2xl": {
      fontSize: "18px",
      color : "#333333"
    },
    xl: {
      fontSize: "16px",
      color : "#333333"
    },
  },
};
const Text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "14px",
    color : "#333333",
    fontWeight : "400"
  },
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "12px",
    fontWeight : "500",
    height : 8
  },
};

const Form: ComponentStyleConfig = {
  baseStyle:{
    requiredIndicator: {
      marginStart: 0
   }
  }
}


const Td: ComponentStyleConfig = {
  baseStyle: {
    padding: 0,
  },
};

const SkeletonText: ComponentStyleConfig = {
  baseStyle:{
    fadeDuration : "1"
  }
}

export const theme = extendTheme({
  config,
  components: {
    FormLabel,
    Form,
    Text,
    Heading,
    Button,
    SkeletonText,
    Td
  },
  colors: {
    brand,
  },

  fonts: {
    body: "tktype,Calibri,Arial,Helvetica,sans-serif",
    heading: "tktype,Calibri,Arial,Helvetica,sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  semanticTokens: {
    colors: {
      white: {
        default: "#FFFFFF",
        _dark: "#000000",
      },
      black: {
        default: "#000000",
        _dark: "#FFFFFF",
      },
    },
  },
});

type themPropType = {
  children: JSX.Element | JSX.Element[];
};

export default function ChakraThemeProvider({ children }: themPropType) {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      {children}
    </ChakraProvider>
  );
}

export const BoxShadowBorderRadius = {
  borderRadius: 8,
  boxShadow: "0px 3px 14px #0000000D",
};
