import { localeStrings } from "./i18n";

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const isNaNRegExp = /^\d+$/;
export const ALLOWED_NO_RECORD_PER_PAGE = 10;
export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const MINIMUM_REQUIRED_AUDIT_SCORE = 80;

export const EIGHT_8D_SECTION = {
    D0_PREPARE_8D : "D0 PLAN AND PREPARE THE 8D",
    D1_PURPOSE : "D1 TEAM MEMBERS",
    D2_CONCERN : "D2 CONCERN",
    D3_CONTAINMENT : "D3 CONTAINMENT",
    D4_ROOT_CAUSE : "D4 ROOT CAUSE",
    FIVE_WHY : "5 WHY",
    D5_6_CORRECTIVE_ACTION : "D5,6 CORRECTIVE ACTIONS",
    D7_PREVENTION_ACTION : "D7 PREVENTIVE ACTIONS",
    D8_CUST_FEEDBACK : "D8 Cust. Feedbacks"
};

export enum EIGHT_D_MENU_OPTIONS  {
    VIEW = "VIEW",
    EDIT = 'EDIT',
    FILL_5_WHY = "FILL_5_WHY",
    FILL_8D = "FILL_8D",
    SEND_8D_LOG = "SEND_8D_LOG",
    CREATE_CHILD = "CREATE_CHILD",
    VIEW_REVISION = "VIEW_REVISION",
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    CONTAINMENT_CUSTOMER_REJECTED = "CONTAINMENT_CUSTOMER_REJECTED"
};

export enum LOG_STATUS {
    DRAFT = 1,
    CONTAINMENT_REVIEW = 2,
    CONTAINMENT_REVIEW_APPROVED = 3,
    CONTAINMENT_REVIEW_REJECTED = 4,
    SUBMITTED = 5,
    APPROVED = 6,
    REJECTED = 7,
    CUSTOMER_APPROVED = 8,
    CUSTOMER_REJECTED = 9,
    CONTAINMENT_CUSTOMER_REJECTED = 10
};

export enum EIGHT_D_CATEGORY {
    OPEN = 0,
    REJECTED = 2,
    COMPLETED = 1
};

export enum EIGHT_D_LISTING_SORT_COLUMN_KEYS {
  ID =  "id",
  STATUS = "status",
  CHAMPION = "champion",
  SUBMITTED_BY = "createdby",
  SUBMITTED_ON = "createdon",
  CUSTOMER = "customer",
  DEFECT_CODE = "defectcode",
  COST_OF_QUALITY = "coq",
  LOCATION = "location"
};

export enum EIGHT_D_LISTING_SORT_TYPE  {
    ASC = "ASC",
    DESC = "DESC"
};

export const USER_ROLES = {
    ADMIN : 1,
    SUPERVISOR : 2,
    AUDITOR : 3,
    MANAGER : 4
};

export enum AUDIT_STATUS_ID {
    ALL = 10,
    DRAFT = 1,
    COMPLETED = 2,
    REJECTED = 3,
    YET_TO_START = 4,
    STOPPED = 5,
    OVER_DUE = 6,
    NOT_APPLICABLE = 9
};
export enum ACTION_TRACKER_STATUS_ID  {
    INPROGRSS = 1,
    COMPLETED = 2,
    REJECTED = 3,
    YET_TO_START = 4,
    STOPPED = 5,
    OVER_DUE = 6
};
export enum SCHEDULE_STATUS {
  SCHEDULED = 7,
  NOT_SCHEDULED = 8,
}


export const AUDIT_LIST_STATUS_FILTER_DATA: any = [
    {
        label : localeStrings('AUDITS.STATUS.YET_TO_START'),
        value : AUDIT_STATUS_ID.YET_TO_START
    },
    {
        label : localeStrings('AUDITS.STATUS.DRAFT'),
        value : AUDIT_STATUS_ID.DRAFT
    },
    {
        label : localeStrings('AUDITS.STATUS.OVER_DUE'),
        value :AUDIT_STATUS_ID.OVER_DUE
    }
];


export const AUDIT_QUESTION_TYPE:any = {
    "text" : 1,
    "textarea" : 2,
    "choice" : 3,
    "checkboxgroup" : 4,
    "select" : 5,
    "multiselect" : 6,
    "file" : 7
};


export const DOC_TYPES = [
  "txt",
  "pdf",
  "doc",
  "csv",
  "xlsx",
  "ppt",
  "pptx",
  "pptm",
  "docx",
  "mp3",
  "mp4",
  "mpeg",
  "heic"
];

export enum APP_MODULES {
    AUDIT =  2,
    EIGHT_D =  4,
    DASHBOARD = 1
};

export enum APP_SUB_MODULES {
    DASHBOARD_AUDIT =  10,
    DASHBOARD_8D = 9,

    LIST_8D = 7,
    APPROVE = 8,
    DEFECT_CODE = 11,

    SCHEDULE_AUDIT = 2,
    LIST_AUDIT = 3,
    ACTION_TRACKER = 4,
    MANAGE_AUDIT = 1
};

   



export const PERFORM_AUDIT_LISTING_HEADERS = {
    AUDIT_ID : "Audit Id",
    AUDIT_NAME : "Audit Name",
    SCHEDULED_BY : "Scheduled By",
    AUDITOR : "Auditor",
    LOCATION : "Location",
    AUDIT_DATE : "Audit Due Date",
    COMPLETED_DATE : "Completed Date",
    STATUS : "Status",
    SCORE : "Score",
    ACTION : "Action"
}


export const FILE_TYPE = {
    EIGHT_D : '8D',
    AUDIT : "AuditPerform",
    MANAGE_AUDIT : "AuditQuestion"
};

export enum EIGHT_D_LISTING_HEADERS{
    ID = "8D Id",
    STATUS = "Status",
    CHAMPION = "Champion",
    CUSTOMER = "Customer",
    LOCATION = "Location",
    ACTION = "Action",
    CREATED_BY = "Created By",
    CREATED_ON = "Created On",
    DEFECT_CODE = "Defect Code",
    COST_OF_QUALITY = "Cost of Quality"
 };
 