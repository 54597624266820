import { Box, Divider as Hr, Text } from "@chakra-ui/react";
import React from "react";

export default function TitleWithLine({children, dashed  = false, bg}: {children?: any, dashed?: boolean, bg?:string}) {
  return (
    <Box position={"relative"} display={"flex"} w={"100%"} alignItems={"center"}>
      <Hr
        borderBottom={dashed ? "2px dotted black" :  "1px solid black"}
        borderBottomColor={"#D9DEE8"}
        opacity={1}
        position={"absolute"}
        zIndex={0}
        my={8}
        w={"100%"}
      />
      <Text my={3} zIndex={10} pr={3} bg={bg ?? 'white'} color={"#1C57A7"} fontWeight={"bold"}>
        {children}
      </Text>
    </Box>
  );
}
