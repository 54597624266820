import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  Flex,
  Tooltip
} from "@chakra-ui/react";
import { useField } from "formik";
import { commonFormComponentType } from "./FormComponent";
import {InfoOutlineIcon} from '@chakra-ui/icons';
import Label from "./label";

export default function FormInput({
  label,
  name,
  error,
  touched,
  placeholder,
  maxLength,
  value,
  setvalue,
  required,
  labelStyle,
  infoIcon,
  disabled,
  fieldType,
  thumbnailImages,
  showLength = false,
  ...rest
}: commonFormComponentType & { showLength?: boolean}) {
  return (
    <FormControl isInvalid={error && touched ? true : false} isRequired={required}>
      <Flex justifyContent="space-between">
        {/* {label && 
          <Flex justifyContent={'space-between'} alignItems={'center'} flex={'01'}><FormLabel {...labelStyle} htmlFor={name}>{label}</FormLabel>
            <Tooltip hasArrow label='Put the job number that billing is under.' openDelay={200} p={2} color='var(--tk-colors-white)' placement="right">
              <InfoOutlineIcon  w={3} h={3} display={infoIcon ? 'block' : 'none'} color={'var(--tk-colors-brand-grayMedium)'} />
            </Tooltip>
          </Flex>}{" "} */}
          {label && <Label name={name} labelStyle={labelStyle} label={label} infoIcon={infoIcon} thumbnailImages={thumbnailImages} />}
        {maxLength && showLength && (
          <Text color="brand.primaryBrand">
            {value?.length || 0}/{maxLength} Characters
          </Text>
        )}
      </Flex>
      <Input type={fieldType} bg={'white'} disabled={disabled} placeholder={placeholder} maxLength={maxLength ?? 500} id={name} value={value} {...rest} />
      <FormErrorMessage as="div">{error}</FormErrorMessage>
    </FormControl>
  );
}

export function InputFormControl({ name, onChange, ...rest }: commonFormComponentType & { showLength?: boolean}) {
  const [field, { error, touched } , helper] = useField(name);
  const handleChange = (e:any) => {
    helper.setValue(e.target.value);
    if(onChange) onChange(e.target.value);
  };
  return <FormInput {...rest} {...field} value={field.value} error={error} touched={touched} onChange={handleChange} />;
}
