import axios from "axios";
import { toast } from "react-toastify";
import { AcquireToken, loginRequest } from "../Auth";
import { adInstance } from "../Auth/authConfigAAD";
import { ToastContainer, TOAST_TYPE } from "../providers/AlertProvider";
import { localeStrings } from "../utils/i18n";
import API_ENDPOINT from "./endpoints";
export const baseURL = process.env.REACT_APP_API_URL;
export const azureFetcher = axios.create({
  baseURL,
  headers: {
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
    "Content-Type" : "application/json",
    "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_SUBCRIPTION_URL}`,
    "Ocp-Apim-Trace": true,
  },
});


azureFetcher.interceptors.response.use(function (response) {
  if(response.config.url === API_ENDPOINT.GET_USER_ROLE && response?.status === 204){
    toast.error(<ToastContainer type={TOAST_TYPE.ERROR}  label={"Error"} message={"The user you are trying to access does not exist."}/>);
    setTimeout(() => {localStorage.clear();  window.location.reload();}, 2000)
  };
  return response;
}, async function (error) {  
  let url = error.config.url;
  if(url !== API_ENDPOINT.GET_USER_ROLE){
    let data = error?.response.data;
    let statusCode = error?.response?.status;
    if(statusCode === 401){
      try{
        let accountArr = await adInstance.getAllAccounts();
        const response = await AcquireToken(adInstance, loginRequest.AD, accountArr?.[0] ?? {});
        azureFetcher.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`;
        error.config.headers.Authorization = `Bearer ${response.accessToken}`;
        return   azureFetcher(error.config);
      }
      catch {
        toast.error(<ToastContainer type={TOAST_TYPE.ERROR}  label={"Error"} message={localeStrings('ERRORS.SESSION_EXPIRED')}/>);
        window.location.reload();
      }
    }
    if(statusCode === 500){
      if(error?.response?.data?.Message.includes("someone has taken this audit")) {}
      if(error?.response?.data?.Message.includes("At the same time someone")) {}
      else toast.error(<ToastContainer type={TOAST_TYPE.ERROR}  label={"Error"} message={data?.Message}/>)
    }
    if(statusCode === 404){
      console.log(error);
      window.location.href = '/404'
      // toast.error(<ToastContainer type={TOAST_TYPE.ERROR}  label={"Error"} message={data?.message}/>)
    }
    if(statusCode === 400){
      console.log(error);
      toast.error(<ToastContainer type={TOAST_TYPE.ERROR}  label={"Error"} message={data?.title}/>)
    }
  }
  return Promise.reject(error);
});