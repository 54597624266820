import { FormControl, FormLabel, FormErrorMessage, Textarea, Text, Flex } from "@chakra-ui/react";
import { useField } from "formik";
import { textAreaType } from "./FormComponent";
import {useRef,useEffect} from 'react';
import Label from "./label";

export default function TextArea({
  label,
  name,
  error,
  touched,
  maxLength,
  setvalue,
  value,
  required,
  labelStyle,
  thumbnailImages,
  disabled,
  ...rest
}: textAreaType) {
const textAreaRef: any = useRef(null);
useEffect(() => {
  handleChange();
},[])

const handleChange = () => {
  textAreaRef.current.style.height = '0px';
  textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
}
useEffect(() => {
  textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
},[value])
  return (
    <FormControl isInvalid={error && touched ? true : false} isRequired={required}>
      <Flex justifyContent="space-between">
        {/* {label && <FormLabel {...labelStyle} htmlFor={name}>{label}</FormLabel>}{" "} */}
        {label && <Label name={name} labelStyle={labelStyle} label={label} thumbnailImages={thumbnailImages} />}
       {maxLength && <Text color="brand.primaryBrand">
          {value?.length || 0}/{maxLength} Characters
        </Text>}
      </Flex>
      <Textarea id={name} disabled={disabled} value={value ?? ""} ref={textAreaRef} style={{height:`0px`}} overflow={'hidden'} className="myTextArea" onInput={handleChange} onCopy={handleChange} {...rest} maxLength={maxLength ?? 1000} />
      <FormErrorMessage as="div">{error}</FormErrorMessage>
    </FormControl>
  );
}

export function TextAreaFormControl({ name,onChange, ...rest }: textAreaType) {
  const [field, { error, touched }, helper ] = useField(name);
  return <TextArea {...rest} {...field}  error={error} touched={touched} onChange={({target}) => {
    helper.setValue(target.value);
    if(onChange) onChange(target.value);
  }}  />;
}
