// @flow Copyright ©2021 ICS. All Rights Reserved.

const API_CONSTANTS = {
    API_PATH_URL : "/api/v1/",
    API_PATH_URL_V2 : "/audit-tracking/api/v2/",
    API_PATH_URL_V3 : "/audit-tracking/api/v3/",
    AUDIT_TRACKING : "/audit-tracking/api/v1/"
};

const API_ENDPOINT = {
    GET_8D_DATA_BY_ID : (id:string) => `${API_CONSTANTS.AUDIT_TRACKING}8d/D1toD7?Audit8DHeaderId=${id}`,
    GET_USER_ROLE : `${API_CONSTANTS.API_PATH_URL}role`,
    GET_USER_LIST : `${API_CONSTANTS.AUDIT_TRACKING}user/businessunitlocation/userlist`,
    GET_AUDIT_PURPOSE : `${API_CONSTANTS.AUDIT_TRACKING}master/auditpurpose`,
    GET_BUSINESS_UNITS : `${API_CONSTANTS.AUDIT_TRACKING}master/businessUnit/userbusinessunit`,
    GET_SECTION_HEADER : (id:number) => `${API_CONSTANTS.AUDIT_TRACKING}Audit/QuestionGroup/GetQuestionGroups?TemplateId=${id}`,
    GET_BUSINESS_UNITS_SUPERVISOR : `${API_CONSTANTS.AUDIT_TRACKING}master/businessUnit/userbusinessunit`,
    GET_LOCATION_BY_BUSINESS_UNIT : (id:number) => `${API_CONSTANTS.AUDIT_TRACKING}master/location/locationbyuserrole?businessUnitId=${id}`,
    GET_STATUS_FILTER : `${API_CONSTANTS.AUDIT_TRACKING}8d/form/status`,
    GET_PURPOSE_DASHBOARD :(filter:string) =>  `${API_CONSTANTS.AUDIT_TRACKING}dashboard/purposetype${filter}`,
    GET_PURPOSE_DASHBOARD_EXCEL : (filter: string) => `${API_CONSTANTS.API_PATH_URL_V3}dashboard/purposetypeexportexcel${filter}`,
    GET_OPENSTAGES_DASHBOARD :(filter:string) =>  `${API_CONSTANTS.AUDIT_TRACKING}dashboard/openstages${filter}`,
    GET_OPENSTAGES_DASHBOARD_EXCEL: (filter:string) => `${API_CONSTANTS.API_PATH_URL_V3}dashboard/openstagesexportexcel${filter}`,
    GET_OPENSTAGESDAYS_DASHBOARD :(filter:string) =>  `${API_CONSTANTS.AUDIT_TRACKING}dashboard/openstagesbydays${filter}`,
    GET_OPENSTAGESDAYS_DASHBOARD_EXCEL : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V3}dashboard/openstagesbydaysviewdetailsexportexcel${filter}`,
    GET_OPENSTAGESDAYSVIEW_DASHBOARD :(filter:string) =>  `${API_CONSTANTS.AUDIT_TRACKING}dashboard/openstagesbydaysviewdetails${filter}`,
    GET_LOCATION_DASHBOARD :(filter:string) =>  `${API_CONSTANTS.AUDIT_TRACKING}dashboard/openbylocation${filter}`,
    GET_AUDIT_STATUS_OVERVIEW_DASHBOARD :(filter:string) => `${API_CONSTANTS.AUDIT_TRACKING}dashboard/auditstatusoverview${filter}`,
    GET_TOP_5_QUESTIONS :(filter:string)=> `${API_CONSTANTS.AUDIT_TRACKING}dashboard/auditstatusoverviewdetails${filter}`,
    GET_AUDIT_LIST_DASHBOARD :(filter:string)=> `${API_CONSTANTS.AUDIT_TRACKING}dashboard/auditlist${filter}`,
    GET_AUDIT_ACTIONTRACKER_OPEN_ITEMS_DASHBOARD : (filter:string)=> `${API_CONSTANTS.AUDIT_TRACKING}dashboard/actiontrackeroveropenitems${filter}`,
    GET_ACTIONTRACKER_LAST_6_MONTHS_DASHBOARD : `${API_CONSTANTS.AUDIT_TRACKING}dashboard/actiontrackerlast6months`,
    GET_ACTIONTRACKER_OVERVIEW_DASHBOARD :(filter:string)=> `${API_CONSTANTS.AUDIT_TRACKING}dashboard/actiontrackeroverview${filter}`,
    GET_SUPERVISOR_8D_TABLE_DATA : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V2}8d/list/userrole${filter}`,
    GET_MANAGE_AUDIT_DATA : (filter:string) => `${API_CONSTANTS.AUDIT_TRACKING}Audit/ManageAudit${filter}`,
    GET_MANAGE_AUDIT_DATA_BY_ID : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V3}Audit/ManageAudit/GetManageAuditByID${filter}`,
    POST_CLONE_AUDIT : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V3}Audit/ManageAudit/CloneAudit${filter}`,
    PATCH_SUPERVISOR_APPROVAL_PREVENTIVE : (filter:string)=> `${API_CONSTANTS.API_PATH_URL_V2}8d/preventiveaction/Approval${filter}`,
    PATCH_SUPERVISOR_REJECT_PREVENTIVE : (filter:string)=> `${API_CONSTANTS.API_PATH_URL_V2}8d/preventiveaction/Rejection${filter}`,
    PATCH_SUPERVISOR_APPROVAL_CONTAINMENT : (filter:string)=> `${API_CONSTANTS.API_PATH_URL_V2}8d/containment/Approval${filter}`,
    PATCH_SUPERVISOR_REJECT_CONTAINMENT : (filter:string)=> `${API_CONSTANTS.AUDIT_TRACKING}8d/containment/Rejection${filter}`,
    PATCH_MARK_AS_INACTIVE : (id:number) => `${API_CONSTANTS.AUDIT_TRACKING}Audit/ManageAudit/InactiveAudit?AuditId=${id}`,
    GET_8D_FORM_DATA_BY_ID : (id:number) => `${API_CONSTANTS.AUDIT_TRACKING}8d/D1toD7?Audit8DHeaderId=${id}`,
    CREATE_CHILD_FORM : (id:number) => `${API_CONSTANTS.API_PATH_URL_V2}8d/form/child?audit8DheaderId=${id}`,
    DELETE_ATTACHMENTS : (filter:string) => `${API_CONSTANTS.AUDIT_TRACKING}8d/concern/attachment${filter}`,
    DELETE_TEAM_MEMBER : (filter:string) => `${API_CONSTANTS.AUDIT_TRACKING}8d/purpose/teammember${filter}`, 
    DELETE_QUESTIONS: (auditId:number,questionId:number) => `${API_CONSTANTS.AUDIT_TRACKING}Audit/ManageAudit/DeleteQuestion?AuditId=${auditId}&questionID=${questionId}`,
    GET_SEND_8D_TO_CUST :(Id:number) => `${API_CONSTANTS.API_PATH_URL_V2}8d/preventiveaction/8DtoCustoerMail?Audit8DHeaderId=${Id}`,

    POST8D_PURPOSE : `${API_CONSTANTS.API_PATH_URL_V2}8d/purpose`,
    POST8D_PROBLEM_SOLVING : `${API_CONSTANTS.API_PATH_URL_V2}8d/problemsolvingteam`,
    POST8D_CONCERN : `${API_CONSTANTS.API_PATH_URL_V3}8d/concern`,
    POST8D_CONTAINMENT : `${API_CONSTANTS.API_PATH_URL_V2}8d/containment`,
    POST8D_DRAFT_5_WHY : `${API_CONSTANTS.API_PATH_URL_V2}8d/audit5why`,
    POST8D_DRAFT_D4 : `${API_CONSTANTS.AUDIT_TRACKING}8d/rootcause`,
    POST8D_DRAFT_D5_6 : `${API_CONSTANTS.API_PATH_URL_V2}8d/correctiveaction`,
    POST8D_DRAFT_D7 : `${API_CONSTANTS.API_PATH_URL_V2}8d/preventiveaction`,
    POST8D_DRAFT_D8: `${API_CONSTANTS.API_PATH_URL_V2}8d/customerfeedback`,
    PERFORM_AUDIT : `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/GetPreschedules`,
    POST_AUDIT: `${API_CONSTANTS.AUDIT_TRACKING}Audit/Perform/PerformAuditSave`,
    POST_SAVE_AUDIT :(filter:any)=> `${API_CONSTANTS.AUDIT_TRACKING}Audit/ManageAudit/SaveAudit${filter}`,
    POST_SAVE_QUESTION :(filter:any)=> `${API_CONSTANTS.API_PATH_URL_V3}Audit/ManageAudit/SaveQuestion${filter}`,
    POST_SAVE_GROUP : `${API_CONSTANTS.AUDIT_TRACKING}Audit/QuestionGroup/SaveGroup`,
    PUT_SAVE_AUDIT : (id:number)=> `${API_CONSTANTS.AUDIT_TRACKING}Audit/ManageAudit/UpdateAudit?AuditId=${id}`,

    UPCOMING_AUDIT : `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/AuditListing`,
    COMPLETED_AUDIT : `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/CompletedListings`,
    GET_ACTION_TRACKER: (filter:string) => `${API_CONSTANTS.AUDIT_TRACKING}Audit/Perform/GetActionTrackers${filter}`,
    GET_ACTION_TRACKER_DETAILS : (id:number) => `${API_CONSTANTS.AUDIT_TRACKING}Audit/Perform/GetActionTrackerDetails?AuditActionTrackerId=${id}`,
    UPDATE_ACTION_TRACKER_DETAILS : `${API_CONSTANTS.AUDIT_TRACKING}Audit/Perform/SaveActionTrackerDetails`,
    DOWNLOAD_PDF : `${API_CONSTANTS.API_PATH_URL_V2}8d/D1toD7/pdf/download`,
    EXPORT_EXCEL_ACTION_TRACKER: (filter:string) => `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/GetActionTrackers/ExportExcel${filter}`,
    EXPORT_EXCEL_AUDIT_LOG: (filter:string) => `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/AuditListing/ExportExcel${filter}`,
    EXPORT_EXCEL_AUDIT_LIST: (filter:string) => `${API_CONSTANTS.AUDIT_TRACKING}dashboard/auditlistexportexcel${filter}`,
    EXPORT_EXCEL_ACTION_TRACKER_OPEN_ITEM : (filter:string)=>`${API_CONSTANTS.AUDIT_TRACKING}dashboard/actiontrackeropenitemsexport${filter}`,
    EXPORT_EXCEL_LAST_SIX_MONTHS_ACTION_TRACKER : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V2}dashboard/actiontrackerlast6monthsdetailsexportexcel${filter}`,
    EXPORT_EXCEL_ACTION_TRACKER_OVERVIEW : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V2}dashboard/actiontrackeroverviewdetailsexportexcel${filter}`,
    EXPORT_EXCEL_AUDIT_STATUS_OVERVIEW : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V2}dashboard/auditoverviewdetailsexportexcel${filter}`,
    EXPORT_EXCEL_TOP_FIVE_QUESTIONS : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V2}dashboard/top5questionwithnocompliantdetailsexportexcel${filter}`,
    EXPORT_EXCEL_DEFECT_CODE : (filter:string) => `${API_CONSTANTS.API_PATH_URL_V2}dashboard/openbydefectCodedetailsexportexcel${filter}`,
    EXPORT_EXCEL_8D_LIST: (filter: string)=>`${API_CONSTANTS.API_PATH_URL_V2}dashboard/8Dlistexport${filter}`,

    EDIT_AUDIT: `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/Edit`,
    UPDATE_AUDIT: `${API_CONSTANTS.AUDIT_TRACKING}Audit/Perform/UpdateAuditPerform`,
    SCHEDULE_AUDIT_LIST : `${API_CONSTANTS.AUDIT_TRACKING}Audit/ScheduleAudit/ScheduleAuditList`,
    POST_SCHEDULE_AUDIT_LIST :  `${API_CONSTANTS.AUDIT_TRACKING}Audit/ScheduleAudit/ScheduleAuditSave`,
    PUT_SCHEDULE_AUDIT_LIST :  `${API_CONSTANTS.AUDIT_TRACKING}Audit/ScheduleAudit/ScheduleAuditEdit`,
    GET_SCHEDULE_AUDIT_DATA :  `${API_CONSTANTS.AUDIT_TRACKING}Audit/ScheduleAudit/ScheduleAuditDates`,
    DELETE_AUDIT_ATTACHMENT: `${API_CONSTANTS.AUDIT_TRACKING}Audit/Perform/DeleteFile`,
    DELETE_COMPLETED_AUDIT: (id:number)=> `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/DeleteAudit?prescheduleID=${id}`,
    MARK_AS_NOT_APPLICABLE: (id:number,comments:string) => `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/updateNotapplicable?prescheduleID=${id}&userComments=${comments}`,

    GET_AUDIT_NAME : `${API_CONSTANTS.AUDIT_TRACKING}dashboard/auditname`,
    GET_ALL_AUDIT_NAME: `${API_CONSTANTS.AUDIT_TRACKING}dashboard/allauditname`,
    GET_ALL_AUDITOR_NAME: `${API_CONSTANTS.AUDIT_TRACKING}user/businessunit/userlist`,
    GET_TOP_5_QUESTION_WITH_NO_COMPLIANT : `${API_CONSTANTS.AUDIT_TRACKING}dashboard/top5questionwithnocompliant`,

    FILE_UPLOAD : `${API_CONSTANTS.AUDIT_TRACKING}/FileUpload`,
    GET_SCHEDULED_DETAILS : `${API_CONSTANTS.AUDIT_TRACKING}/Audit/ManageAudit/GetScheduledDetails`,
    PDF_AUDIT : `${API_CONSTANTS.API_PATH_URL_V3}/Audit/Perform/pdf/download`,
    GET_DEFECT_CODE : `${API_CONSTANTS.API_PATH_URL_V2}defectcode/defectcodeactive`,
    GET_ALL_DEFECT_CODE : `${API_CONSTANTS.API_PATH_URL_V2}defectcode`,
    FETCH_8D_LOG_LIST: `${API_CONSTANTS.API_PATH_URL_V2}8d`,
    AUDIT_LIST : `${API_CONSTANTS.API_PATH_URL_V2}Audit/Perform/AvailableAuditList`,
    POST_AUDIT_LIST : `${API_CONSTANTS.API_PATH_URL_V3}Audit/Perform/AvailableAuditsave`,
    CONTAINMENT_CUSTOMER_REJECT : `${API_CONSTANTS.API_PATH_URL_V3}8d/containment/customerrejection`,
};


export default API_ENDPOINT;