import { lazy, useEffect, useMemo } from "react";
import { RouterProvider, createBrowserRouter, redirect, Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import UAParser from "ua-parser-js";
import { useQuery } from "@tanstack/react-query";

import { AcquireToken, loginRequest } from "../Auth";
import { azureFetcher } from "../api/fetcher";
import { FetchMenu } from "../api/menu";

import { useAppContext } from "../providers/AppProviders";
import { BrandSpinner } from "../Component/ui/BrandSpinner";
import { Flex } from "@chakra-ui/react";
import { APP_MODULES, APP_SUB_MODULES, EIGHT_D_CATEGORY } from "./const";
import { getActionTrackerDetailData } from "../api/ActionTracker";
import { getManageAuditDataById } from "../api/ManageAudit";
import { MODULE_POSITION } from "../Pages/Create8D";

// import ChooseModule from "../Component/parts/ChooseModule";
const AuditTrackerDetail = lazy(() => import("../Pages/ActionTracker"));
const AuditTrackerList = lazy(() => import("../Pages/ActionTracker/ATlist"));
const AuditDash = lazy(() => import("../Pages/AuditDashboard"));
const CreateChild8D = lazy(() => import("../Pages/Create8D"));
const NoMatch = lazy(() => import("../Pages/NoMatch/NoMatch"));
const Home = lazy(() => import("../Pages/Home/Home"));
const AuditLog = lazy(() => import("../Pages/AuditLog/AuditLog"));
const SupervisorLogin8D = lazy(() => import("../Pages/8DLogSupervisor"));
const ManageAudit = lazy(() => import("../Pages/ManageAudit/ManageAudit"));
const CreateNewAudit = lazy(() => import("../Pages/ManageAudit/CreateAudit"));
const ViewAudit = lazy(() => import("../Pages/ManageAudit/viewAudit"));
const Dashboard8D = lazy(() => import("../Pages/8DLog/Dashboard8D"));
const Dashboard = lazy(() => import("../Pages/8DDashboard/Dashboard"));
const Audit = lazy(() => import("../Pages/PerformAudit"));
const TakeNewAudit = lazy(() =>
  import("../Pages/PerformAudit").then((module) => ({ default: module.TakeNewAudit }))
);
const DraftAudit = lazy(() =>
  import("../Pages/PerformAudit").then((module) => ({ default: module.DraftAudit }))
);
const ViewCompletedAudit = lazy(() =>
  import("../Pages/PerformAudit/DetailAudit").then((module) => ({ default: module.ViewCompletedAudit }))
);

const Details8D = lazy(() => import("../Pages/8DLogSupervisor/Details"));
const ScheduleAudit = lazy(() => import("../Pages/ScheduleAudit/ScheduleAudit"));
const AuditDashboard = lazy(() => import("../Pages/AuditDashboard/AuditDashboard"));
const ActionTrackerDashboard = lazy(() => import("../Pages/AuditDashboard/ActionTrackerDashboard"));
const Create8D = lazy(() =>
  import("../Pages/Create8D").then((module) => ({ default: module.Create8D }))
);
const Open8D = lazy(() =>
  import("../Pages/8DLog/Dashboard8D").then((module) => ({ default: module.Open8D }))
);
const DefectCode = lazy(() => import("../Pages/DefectCode/index"));
const AuditUpcoming = lazy(() =>
  import("../Pages/AuditLog/AuditLog").then((module) => ({ default: module.AuditUpcoming }))
);
const AuditCompleted = lazy(() =>
  import("../Pages/AuditLog/AuditLog").then((module) => ({ default: module.AuditCompleted }))
);
const AuditList = lazy(() =>
  import("../Pages/AuditLog/AuditListTable")
);
const Supervisor8DContainer = lazy(() =>
  import("../Pages/8DLogSupervisor").then((module) => ({ default: module.Supervisor8DContainer }))
);

export const PATH = {
  DASHBOARD_8D : '/8DDashboard',
  DASHBOARD_AUDIT : "/AuditDashboard",
  LIST_8D : "/8DLog",
  LIST_AUDIT : "/auditlog",
  APPROVE : '/MyApproval',
  DEFECT_CODE : '/DefectCode',
  SCHEDULE_AUDIT : '/ScheduleAudit',
  ACTION_TRACKER : '/AuditTracker',
  MANAGE_AUDIT : '/manageAudit',
  CREATE_AUDIT : '/createAudit',
  TAKE_AUDIT : "/takeAudit",
  DRAFT_AUDIT : "/draftAudit",
  TAKE_TEMPLATE_AUDIT : "/takenewaudit",
  VIEW_COMPLETED_AUDIT : "/viewcompletedaudit",
}
export const CHILD_PATH = {
  LIST_8D_REJECTED : 'rejected',
  LIST_8D_COMPLETED : 'completed',
  LIST_8D_APPROVE_APPROVED : 'tab/approved',
  LIST_8D_APPROVE_REJECTED : 'tab/rejected',
  LIST_AUDIT_COMPLETED :  'completed',
  LIST_AUDIT_AUDITLIST : "auditlist"
}

const parser = new UAParser();
parser.setUA(navigator.userAgent);
let { browser, os } = parser.getResult();


export function UseRoute() {
  const {  menu, setMenu } = useAppContext();
  const { instance, accounts } = useMsal();
  const { data: accessToken, isError } = useQuery(
    ["accessToken"],
    () => AcquireToken(instance, loginRequest.AD, accounts[0]),
    {
      enabled: accounts.length ? true : false,
      onSuccess: (res: any) => {
        azureFetcher.defaults.headers.common.Authorization = `Bearer ${res.accessToken}`;
        azureFetcher.defaults.headers.common["Authorization-Source"] = "AD";
        azureFetcher.defaults.headers.common["deviceInfo"] = JSON.stringify({
          carrier: "unkown",
          deviceID: browser.version,
          deviceName: browser.version,
          platform: os.name,
          version: os.version,
        });
        return res.accessToken;
      },
    }
  );

  // eslint-disable-next-line

  useEffect(() => {
    if (isError) {
      instance.logoutRedirect();
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    if (accessToken) {
      FetchMenu()
        .then((e) => {
          setMenu({ loading: false, data: e });
        })
        .catch(() => {});
    }
    // eslint-disable-next-line
  }, [accessToken]);


  let moduleID = useMemo(() => menu.data?.application?.applicationRoles?.applicationModules?.map((i:any) => i?.moduleUniqueId) ?? [],[menu]);
  let entityID = useMemo(() => menu.data?.application?.applicationRoles?.applicationModules?.map((i:any) => i?.applicationEntity)?.flat()?.map((i:any) => i?.entityUniqueId) ?? [] ,[menu]);


  const findElementPermission = (
    component: any,
    module: APP_MODULES,
    sub_module: APP_SUB_MODULES
  ) =>
    moduleID.includes(module) && entityID.includes(sub_module) ? (
      component
    ) : (
      <Navigate to={"/notFound"} />
    );
  var element :any = [];
if(accessToken)
  element = createBrowserRouter([
    {
      path: "/",
      element: <Home moduleID={moduleID} entityID={entityID} />,
      children: [
        { path : PATH.DASHBOARD_8D, element :  findElementPermission(<Dashboard />, APP_MODULES.DASHBOARD , APP_SUB_MODULES.DASHBOARD_8D) },
        {
          path: PATH.DASHBOARD_AUDIT,
          element: findElementPermission(<AuditDash />, APP_MODULES.DASHBOARD , APP_SUB_MODULES.DASHBOARD_AUDIT),
          children: [
            { index: true, element: <AuditDashboard /> },
            { path: "/AuditDashboard/ActionTracker", element: <ActionTrackerDashboard /> },
          ],
        },

        { path: PATH.LIST_8D, element: findElementPermission(<Dashboard8D />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.LIST_8D), children : [
          { index : true, element : <Open8D  category={EIGHT_D_CATEGORY.OPEN}/>},
          { path : "rejected", element : <Open8D  category={EIGHT_D_CATEGORY.REJECTED}/>},
          { path : "completed", element : <Open8D  category={EIGHT_D_CATEGORY.COMPLETED}/>}
        ] },
        { path: "/view8D/:id", element:  findElementPermission(<Details8D onlyView={true} />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.LIST_8D)  },

        { path: "/create8d", element:   findElementPermission(<Create8D />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.LIST_8D) },
        { path: "/revise8Dfill/:id", element:   findElementPermission(<CreateChild8D position={MODULE_POSITION.FIVE_WHY} isChild={true} />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.LIST_8D) },
        { path: "/revisied8D/:id", element:   findElementPermission(<CreateChild8D position={0} isChild={true} />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.LIST_8D) },
        { path: "/edit8d/:id", element: findElementPermission(<CreateChild8D   isChild={false} />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.LIST_8D) },
        { path: "/FiveWhy/:id", element: findElementPermission(<CreateChild8D fillFiveWhy={true} isChild={false} />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.LIST_8D) },
        { path: "/fillD8/:id", element: findElementPermission(<CreateChild8D fill8D={true} isChild={false} />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.LIST_8D) },
        
        { path: PATH.APPROVE, element:  findElementPermission(<SupervisorLogin8D />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.APPROVE),children : [
          {
            index : true, element: <Supervisor8DContainer selected={0} />
          },
          {
            path: CHILD_PATH.LIST_8D_APPROVE_APPROVED, element: <Supervisor8DContainer selected={1} />
          },
          {
            path: CHILD_PATH.LIST_8D_APPROVE_REJECTED, element: <Supervisor8DContainer selected={2} />
          }
        ]},
        { path: PATH.APPROVE + "/:id", element:   findElementPermission(<Details8D  onlyView={false} />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.APPROVE) },

        { path: PATH.DEFECT_CODE, element:  findElementPermission(<DefectCode />, APP_MODULES.EIGHT_D , APP_SUB_MODULES.DEFECT_CODE) },

        { path: PATH.LIST_AUDIT,element : findElementPermission(<AuditLog />, APP_MODULES.AUDIT , APP_SUB_MODULES.LIST_AUDIT) , children : [
          { element  :<AuditUpcoming/>,  index : true},
          { element  :<AuditCompleted /> , path : CHILD_PATH.LIST_AUDIT_COMPLETED},
          { element  :<AuditList /> , path : CHILD_PATH.LIST_AUDIT_AUDITLIST},
        ] },
        { path:  `${PATH.VIEW_COMPLETED_AUDIT}/:prescheduleID`, element:  findElementPermission(<ViewCompletedAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.LIST_AUDIT) },
        { path:  `${PATH.DRAFT_AUDIT}/:prescheduleID`, element:  findElementPermission(<DraftAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.LIST_AUDIT) },
        { path: `${PATH.TAKE_AUDIT}/:prescheduleID` , element:  findElementPermission(<Audit />, APP_MODULES.AUDIT , APP_SUB_MODULES.LIST_AUDIT) },
        { path:  `${PATH.TAKE_TEMPLATE_AUDIT}/:auditTemplateId/:businessUnitId/:locationId`, element:  findElementPermission(<TakeNewAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.LIST_AUDIT) },
        { path: "/viewAudit/:id",
          element: findElementPermission(<ViewAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.LIST_AUDIT),
           id : "viewAudit",
          errorElement: <Navigate to={"/"} />,
          loader: async ({ params }: any) => {
            const editItem :any = await getManageAuditDataById({AuditId:params.id});
            let value = editItem?.['pagedData']?.[0] ?? null;
            if(value) return {value};
            else return redirect(`/`);
          },
        },

        { path: PATH.MANAGE_AUDIT, element:  findElementPermission(<ManageAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.MANAGE_AUDIT) },
        { path: PATH.CREATE_AUDIT, element:  findElementPermission(<CreateNewAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.MANAGE_AUDIT) },
        { path: "/editAudit/:editID", element:  findElementPermission(<CreateNewAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.MANAGE_AUDIT) },
        { path: "/scheduledAuditEdit/:editID", element:  findElementPermission(<CreateNewAudit isScheduleAuditEdit={true} />, APP_MODULES.AUDIT , APP_SUB_MODULES.MANAGE_AUDIT) },
        { path: "/copyTemplate/:copyTemplateID", element:  findElementPermission(<CreateNewAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.MANAGE_AUDIT) },

        { path: PATH.SCHEDULE_AUDIT, element:  findElementPermission(<ScheduleAudit />, APP_MODULES.AUDIT , APP_SUB_MODULES.SCHEDULE_AUDIT) },

        {
          path: PATH.ACTION_TRACKER,
          children: [
            { index: true, element:  findElementPermission(<AuditTrackerList />, APP_MODULES.AUDIT , APP_SUB_MODULES.ACTION_TRACKER)  },
            {
              path: ":ActionTrackerID",
              id : "root",
              errorElement: <Navigate to={"/"} />,
              children : [
                { index: true, element: <AuditTrackerDetail viewMode={false} /> },
                { path: "View", element: <AuditTrackerDetail viewMode={true} /> },
              ],
              loader: async ({ params }: any) => {
                let data = await getActionTrackerDetailData({ auditActionTrackerId: params.ActionTrackerID });
                if(data.length) return data;
                else return redirect(`/AuditTracker`);
              },
            },
          ],
        },
        { path: "*", element: <NoMatch moduleID={moduleID} entityID={entityID} /> },
        {
          path: "/notFound",
          element: <NoMatch moduleID={moduleID} entityID={entityID} title="User does not have the rights to access the module" />,
        }
      ],
    },
  ]);

  if (menu.loading)
    return (
      <Flex height={"100vh"} width="100%" alignItems={"center"} justifyContent="center">
        <BrandSpinner />
      </Flex>
    );
  return <RouterProvider router={element} />;
}


export function FindComponentPath(entityUniqueId: number) {
  
  switch (entityUniqueId) {
    case  APP_SUB_MODULES.DASHBOARD_8D:
      return PATH.DASHBOARD_8D;
    case APP_SUB_MODULES.LIST_8D:
     
      return PATH.LIST_8D;
    case APP_SUB_MODULES.APPROVE:
      return PATH.APPROVE;
  
    case APP_SUB_MODULES.LIST_AUDIT:
      return PATH.LIST_AUDIT;

    case APP_SUB_MODULES.ACTION_TRACKER:
      return PATH.ACTION_TRACKER;
    case APP_SUB_MODULES.SCHEDULE_AUDIT:
      return PATH.SCHEDULE_AUDIT;
    case APP_SUB_MODULES.DASHBOARD_AUDIT:
      return PATH.DASHBOARD_AUDIT;
    case APP_SUB_MODULES.DEFECT_CODE:
      return PATH.DEFECT_CODE;
      case APP_SUB_MODULES.MANAGE_AUDIT:
        return PATH.MANAGE_AUDIT;
    default:
      return '/404';
  }
}

