import { Box,Flex,Text } from "@chakra-ui/react";
import React from "react";
import { ImageUploaderFormControl, TextAreaFormControl } from "../../Component/formComponent";
import { FeedBackFormControl } from "../../Component/formComponent/FeedBack";
import { localeStrings } from "../../utils/i18n";
import { generateID } from "../../utils/utils";
import { AccordionComponent } from "./AccordionTitleDescription/Accordion";

import AccordionTitleDescription from './AccordionTitleDescription/AccordionTitleDescription.json';
export default function D8FeedBack({view, audit8dHeaderId, audit8DLogId }: {view : boolean; audit8dHeaderId?:number; audit8DLogId? : number}) {
  return (
    <Box>
       <Flex justifyContent={"space-between"}>
       <Text pb={2} fontWeight="bold" fontSize={'md'}>D8 Cust. Feedbacks </Text>
       {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
       </Flex>
      <AccordionComponent Title={AccordionTitleDescription.D8.Title} Description={AccordionTitleDescription.D8.Description} />
      <FeedBackFormControl
       data-testid="cust_feedback"
        required={true}
        disabled={view}
        label={localeStrings("CREATE_8D_LOG.CUST_FEEDBACK.HOW_CUST_STATISFIED")}
        name={"customerSatisfication"}
      />
      <Box my={6}>
      <TextAreaFormControl
        data-testid="cust_feedback_note"
        required={true}
        label={localeStrings("CREATE_8D_LOG.CUST_FEEDBACK.NOTE")}
        placeholder={localeStrings("PLACEHOLDERS.ENTER_NOTES")}
        maxLength={1000}
        disabled={view}
        name={"customerNotes"}
      />
      </Box>
      <ImageUploaderFormControl
        data-testid="cust_feedback_attachment"
        label={localeStrings("CREATE_8D_LOG.UPLOAD_IMAGE")}
        name={"attachement"}
        required={false}
        disabled={view}
        audit8dHeaderId={audit8dHeaderId}
      />
    </Box>
  );
}
