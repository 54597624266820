import { azureFetcher } from "./fetcher";
import API_ENDPOINT from "./endpoints";

export async function getManageAuditData({  PageNumber, RecordsPerPage, StatusID, businessUnit, location, searchString  }: any) {
  let statusFilter = StatusID !== 0 ? `&SortOrder=${StatusID}` : "";
  let businessUnitFilter = businessUnit !== 0 ? `&BusinessUnitId=${businessUnit}` : "";
  let locationFilter = location !== 0 ? `&LocationId=${location}` : "";
  let searchStringFilter = searchString ? `&auditTemplateId=${searchString}` : "";
    try {
      const url = `?PageNumber=${PageNumber}&pageSize=${RecordsPerPage}${statusFilter}${businessUnitFilter}${locationFilter}${searchStringFilter}`;
      const data = await azureFetcher.get(API_ENDPOINT.GET_MANAGE_AUDIT_DATA(url));
      if (data.status === 200) return data.data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function getManageAuditDataById({ AuditId }: any) {
    try {
      const url =  `?AuditID=${AuditId}`
      const data = await azureFetcher.get(API_ENDPOINT.GET_MANAGE_AUDIT_DATA_BY_ID(url));
      if (data.status === 200) return {pagedData:[data.data?.[0]]};
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function getBusinessUnit() {
    try {
      const data = await azureFetcher.get(API_ENDPOINT.GET_BUSINESS_UNITS);
      if (data.status === 200) return data.data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function getSectionHeader({id}:any) {
    try {
      const data = await azureFetcher.get(API_ENDPOINT.GET_SECTION_HEADER(id));
      if (data.status === 200) return data.data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function saveAudit({ body, AuditId }: any) {
    try {
      const filter = AuditId ? `?AuditId=${AuditId}`:'';
      const data = await azureFetcher.post(API_ENDPOINT.POST_SAVE_AUDIT(filter), JSON.stringify(body));
      if (data.status === 200) return data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function saveGroup({ body }: any) {
      return await azureFetcher.post(API_ENDPOINT.POST_SAVE_GROUP, JSON.stringify(body));
  }

  export async function updateAudit({ body,id }: any) {
    try {
      const data = await azureFetcher.put(API_ENDPOINT.PUT_SAVE_AUDIT(id), JSON.stringify(body));
      if (data.status === 200) return data.status;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function markAsInactive({id}:any) {
    try {
      const data = await azureFetcher.patch(API_ENDPOINT.PATCH_MARK_AS_INACTIVE(id));
      if (data.status === 200) return data.data;
      return data.status;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function deleteQuestions({auditId,questionId}:any) {
    try {
      const data = await azureFetcher.delete(API_ENDPOINT.DELETE_QUESTIONS(auditId,questionId));
      if (data.status === 200) return data.status;
      return data.status;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function cloneAudit({ AuditID }: any) {
    try {
      let filter = `?AuditID=${AuditID}`
      const data = await azureFetcher.post(API_ENDPOINT.POST_CLONE_AUDIT(filter), JSON.stringify({}));
      if (data.status === 200) return data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function saveQuestion({ body, QuestionID }:any) {
    try {
      const filter = `${QuestionID ? `?QuestionID=${QuestionID}`:''}`
      const data = await azureFetcher.post(API_ENDPOINT.POST_SAVE_QUESTION(filter), JSON.stringify(body));
      if (data.status === 200) return data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function getScheduledDetails({ values }: any) {
      return await azureFetcher.get(API_ENDPOINT.GET_SCHEDULED_DETAILS + `?AuditID=${values.id}`);
  }