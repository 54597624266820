import { Box, Flex, FormLabel, FormLabelProps, Tooltip, useDisclosure } from '@chakra-ui/react';
import React, {useState} from 'react';
import {InfoOutlineIcon} from '@chakra-ui/icons';
import ThumbnailView from '../../Pages/ManageAudit/components/thumbnailView';
import { ViewImage } from './imageUploader';

export default function Label({infoIcon,labelStyle, label, name, thumbnailImages}:{
    infoIcon?: boolean;
    labelStyle?: FormLabelProps;
    label : string;
    name : string;
    thumbnailImages ?: any;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [imageUrl, setImageUrl] = useState('');
  return (
    <Box>
        {isOpen && <ViewImage imageUrl={imageUrl} setImageUrl={setImageUrl} onClose={onClose}  isOpen={isOpen} />}
        <Flex justifyContent={'space-between'} alignItems={'center'} flex={'01'}><FormLabel {...labelStyle} htmlFor={name}>{label}</FormLabel>
            <Tooltip hasArrow label='Put the job number that billing is under.' openDelay={200} p={2} color='var(--tk-colors-white)' placement="right">
              <InfoOutlineIcon  w={3} h={3} display={infoIcon ? 'block' : 'none'} color={'var(--tk-colors-brand-grayMedium)'} />
            </Tooltip>
          </Flex>
          {thumbnailImages?.length ? <ThumbnailView Images={thumbnailImages} setImageUrl={setImageUrl} OnOpen={onOpen} /> : <></>}
    </Box>
  )
}
