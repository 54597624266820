import {
  //  LogLevel,
  IPublicClientApplication,
  AccountInfo,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
export const loginRequest = {
  AD: {
    scopes: [
      "api://68151760-8f49-4b0a-8375-0b1479f2d8ad/Authorization"
      
    ],
  },
  B2C: {
    scopes: [
      "https://tkpooc.onmicrosoft.com/464ebbbc-e931-448c-80f9-08a793dd5c10/Authorization.Read",
    ],
  },
};
export const system = {
  loggerOptions: {
  },
};
export const cache = {
  cacheLocation: "localStorage",
  storeAuthStateInCookie: false,
};
export function handleRedirectLogin(Instance: IPublicClientApplication) {
  Instance.handleRedirectPromise()
    .then((a) => {
      if (a?.account) {
        Instance.setActiveAccount(a?.account);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
export async function AcquireToken(
  Instance: IPublicClientApplication,
  lr: any,
  account: AccountInfo
) {
  try {
    const response = await Instance.acquireTokenSilent({
      ...lr,
      account,
    });
    console.log('token ',response);
    
    return response;
  } catch (e) {
    console.log('error ',e);
    if(e === InteractionRequiredAuthError) {
      Instance.handleRedirectPromise()
      .then((a) => {
        if (a?.account) {
          Instance.setActiveAccount(a?.account);
        }
      })
      .catch((err) => {
        return Promise.reject("error");
      });
    }
    return Promise.reject("error");
  }
}
