import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect, ReactNode, createContext, useContext } from "react";
import { toast } from "react-toastify";
import { images } from "../assets";
import Button from "../Component/ui/Button";

interface AppAlertProps {
  visible: boolean;
  openAlert: () => void;
  closeAlert: () => void;
  setAlertProp: (e: AlertProps) => void;
}
export interface AlertProps {
  type: "confirmation" | "success" | "confirmationError" |  "confirmationErrorModal";
  alertMessage: string;
  alertLabel: string;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
  onClickPrimary: () => any;
  onClickSecondary?: () => void;
  closeIcon ?: boolean;
}

const AlertContext = createContext<any>({});

export const useAlertContext = () => {
  const data: AppAlertProps = useContext(AlertContext);
  return data;
};

export enum TOAST_TYPE {
  SUCCESS = "success",
  ERROR = "error"
}

export let ToastContainer = ({type,label, message} : {type : TOAST_TYPE; label : string; message : string }) => <Box>
    <Text fontSize={"md"} color={type === TOAST_TYPE.SUCCESS ? "brand.turquoise"  : "brand.red"} >{label}</Text>
    <Text >{message}</Text>
  </Box>

export default function AlertContextProvider({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [alertProp, setAlertProp] = useState<AlertProps>({
    primaryButtonLabel: "",
    secondaryButtonLabel: "",
    type: "success",
    alertMessage: "",
    alertLabel: "",
    onClickPrimary: () => {},
    onClickSecondary: () => {},
    closeIcon:false
  });

  

  useEffect(() => {
    if (isOpen) {
      if (alertProp.type === "success") {
        toast.success(<ToastContainer type={TOAST_TYPE.SUCCESS} label={alertProp.alertLabel} message={alertProp.alertMessage} />);
        alertProp.onClickPrimary();
      }
    }
    // eslint-disable-next-line
  }, [isOpen, onOpen]);

  return (
    <AlertContext.Provider
      value={{ visible: isOpen, openAlert: onOpen, closeAlert: onClose, setAlertProp }}
    >
      {children}
      {alertProp.type !== "success" && alertProp.type !== "confirmationError" ? <AlertModel   
        onClose={() => !alertProp?.secondaryButtonLabel && !alertProp?.closeIcon ? alertProp?.onClickPrimary() : onClose()} 
        isOpen={isOpen}
        alertProp={alertProp}  /> : ""}
    </AlertContext.Provider>
  );
}

export function FindTextColorByType(type:string) {
  switch (type) {
    case "success":
    case "confirmation":
    case "confirmationError":
    case "confirmationErrorModal":
    default:
      return "white";
  }
}
export function FindLabelColorByType(type:string) {
  switch (type) {
    case "success":
      return "brand.turquoise";
    case "confirmation":
      return "brand.secondaryMedium";
    case "confirmationError":
    case "confirmationErrorModal":
      return "#E60050";
    default:
      return "green";
  }
}
export function FindBgColorByType(type:string) {
  switch (type) {
    case "success":
      return "brand.turquoise";
    case "confirmation":
      return "brand.primaryBrand";
      case "confirmationError":
      case "confirmationErrorModal":
        return "#E60050";
    default:
      return "green";
  }
}
export function FindIconByType(type: string) {
  switch (type) {
    case "success":
      return images.alert.success;
    case "confirmation":
      return images.alert.confirmation;
    case "confirmationError":
    case "confirmationErrorModal":
      return images.alert.confirmationError;
    default:
      break;
  }
}

export function AlertModel({
  onClose,
  isOpen,
  alertProp
}: 
{
  onClose : () => void,
  isOpen: boolean,
  alertProp: AlertProps
}) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered scrollBehavior={"inside"} closeOnOverlayClick={false} >
      <ModalOverlay />
      <ModalContent>
      {alertProp?.closeIcon ? <ModalCloseButton /> : <></>}
        <ModalBody>
          <Flex p={4} direction={"column"} alignItems="center">
            <Image src={FindIconByType(alertProp.type)} />
            <Text fontSize={"18px"}  textAlign={"center"} noOfLines={[1, 2, 4]} color={FindLabelColorByType(alertProp.type)} pt="3">
              {alertProp.alertLabel}
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter
          p="4"
          bg="#efefef"
          borderTop={"1px solid grey"}
          borderBottomRightRadius={"md"}
          borderBottomLeftRadius={"md"}
          display="flex"
          flexDirection="column"
        >
          <Text align={'center'} width="100%" noOfLines={[1, 2, 4]}>{alertProp.alertMessage}</Text>
          <Box mt={4}>
            {alertProp?.secondaryButtonLabel && (
              <Button
                mr="3"
                variant="outline"
                bg={"white"}
                _hover={{
                  outline: `1px solid ${FindBgColorByType(alertProp.type)}`,
                }}
                border="none"
                color={"black"}
                onClick={alertProp.onClickSecondary}
                data-testid={"SECONDARY_BUTTON"}
              >
                {alertProp.secondaryButtonLabel}
              </Button>
            )}
            <Button
              ml="3"
              variant="outline"
              _hover={{
                bg: FindBgColorByType(alertProp.type),
                opacity: 0.9,
              }}
              color={FindTextColorByType(alertProp.type)}
              bg={FindBgColorByType(alertProp.type)}
              onClick={alertProp.onClickPrimary}
              data-testid={"PRIMARY_BUTTON"}
            >
              {alertProp.primaryButtonLabel}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
