import { Grid, Box,Text, Flex } from "@chakra-ui/react";
import { localeStrings } from "../../utils/i18n";
import { TextAreaFormControl, DatePickerFormControl, SelectFormControl, MultiSelectFormControl} from "../../Component/formComponent";
import AccordionTitleDescription from './AccordionTitleDescription/AccordionTitleDescription.json';
import { AccordionComponent } from "./AccordionTitleDescription/Accordion";
import { useQuery } from "@tanstack/react-query";
import { FetchUserList } from "../../api/User";
import { useField, useFormikContext } from 'formik';
import { CloseIcon } from "@chakra-ui/icons";
import { EIGHT_8D_SECTION } from "../../utils/const";
import { generateID } from "../../utils/utils";

function D5CorrectiveAction({view, data, audit8DLogId}: {view : boolean; data : any; audit8DLogId?:number;}) {
    const [field,, helper] = useField("responsiblePerson");
    const { values }: any = useFormikContext();
    const { data: assignee } = useQuery(
      ["fetchUserData", values.businessUnit, values.location],
      async () =>
        await FetchUserList({ businessunitId: data?.[0]?.businessUnit?.value, locationid: data?.[0]?.location?.value }),
      {
        enabled : data?.[0]?.businessUnit?.value && data?.[0]?.location?.value ? true : false,
      }
    );
    return (
        <>
            <Flex justifyContent={"space-between"}>
                <Text pb={2} fontWeight="bold" fontSize={'md'}>{EIGHT_8D_SECTION.D5_6_CORRECTIVE_ACTION}
                </Text>
                {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
            </Flex>
            <AccordionComponent 
                Title={AccordionTitleDescription.D5.Title} 
                Description={AccordionTitleDescription.D5.Description}
                Title2={AccordionTitleDescription.D6.Title}
                Description2={AccordionTitleDescription.D6.Description}  />
            <Box>
                <MultiSelectFormControl
                    options={assignee?.data?.map((i:any) => ({value : i.email, label : i.userFLName})) ?? []}
                    required={true}
                    name={"responsiblePerson"}
                    label={localeStrings("8D_LOGS.FILTER.RESPONSIBLE_PERSON")}
                    placeholder={localeStrings("PLACEHOLDERS.SELECT_RESPONSIBLE_PERSON")}
                />
                <Box my={5}>
                    {field.value?.length ? (
                    <Flex flexWrap={"wrap"} gap={2}>
                        {field.value.map((i: any) => (
                        <Text p={1} bg={"brand.grayLight"} borderRadius={5}>
                            {i.label}
                            <CloseIcon
                            h={2}
                            w={2}
                            mx={2}
                            color={"brand.red"}
                            cursor="pointer"
                            onClick={() =>
                                helper.setValue(
                                field.value.filter((item: any) => item.value !== i.value)
                                )
                            }
                            />
                        </Text>
                        ))}
                    </Flex>
                    ) : null}
                </Box>
            </Box>
            <Grid templateColumns={["repeat(1, 1fr)","repeat(1, 1fr)","repeat(2, 1fr)","repeat(2, 1fr)"]} gap={6} >
                <DatePickerFormControl
                    required={true}
                    placeholder={localeStrings("MM/DD/YYYY")}
                    label={localeStrings("CREATE_8D_LOG.TARGET_DATE")}
                    data-testid="target_date"
                    name="targetDate"
                    disabled={view}
                    />
                <DatePickerFormControl
                    required={true}
                    placeholder={localeStrings("MM/DD/YYYY")}
                    label={localeStrings("CREATE_8D_LOG.FOLLOW_UP_DATE")}
                    data-testid="followup_date"
                    name="followUpDate"
                    disabled={view}
                />
            </Grid>
            <Box width={[ '100%','100%','65%','100%']} my="5">
                <TextAreaFormControl
                    required={true}
                    placeholder={localeStrings("PLACEHOLDERS.ENTER_CORRECTIVE_ACTION")}
                    data-testid="corrective_action"
                    label={localeStrings("CREATE_8D_LOG.D5_CORRECTIVE_ACTION")}
                    name="correctiveAction"
                    maxLength={1000}
                    disabled={true}
                />
            </Box>
        </>
    );
}

export default D5CorrectiveAction;